import React, { Component } from 'react';
import '../Thanks/style.scss';
import { withTranslation } from 'react-i18next';
import { share } from '../../services/social';
import facebookIcon from '../../assets/images/social-icons/facebook.png';
import gmailIcon from '../../assets/images/social-icons/gmail.png';
import socialIcon from '../../assets/images/social-icons/social.png';
import twitterIcon from '../../assets/images/social-icons/twitter.svg';
import { mixpanelTrack, EVENT_NAMES } from '../../utils/mixpanel';

class WillReadLater extends Component {
  handleShareClick = (social) => {
    const url = window.location.origin;
    const text = this.props.t('metaTitle');
    mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_SHARE_BUTTON, {
      social,
      url,
    });

    share(social, url, text);
  };
  render() {
    const { t } = this.props;

    return (
      <section className="thanks">
        {/* <div className="thanks-fallen-image">
          <img alt="" src={fallen.fallen_image_url} />
        </div> */}
        <div className="thanks-headlines">
          <div className="thanks-text">{t('thanksForLater')}</div>
          <div className="thanks-fallen-info">{t('fallenSoldiers')}</div>
        </div>
        <div className="thanks-buttons">
          <button
            onClick={() => {
              mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_ANOTHER_FALLEN);
              window.location.href = '/';
            }}
          >
            <span>{t('readForRandom')}</span>
          </button>
        </div>
        <div className="thanks-social">
          <span>{t('shareIn')}</span>
          <div>
            <button onClick={() => this.handleShareClick('email')}>
              <img alt="" src={gmailIcon} />
            </button>
            <button onClick={() => this.handleShareClick('whatsapp')}>
              <img alt="" src={socialIcon} />
            </button>
            <button onClick={() => this.handleShareClick('twitter')}>
              <img alt="" src={twitterIcon} />
            </button>
            <button onClick={() => this.handleShareClick('facebook')}>
              <img alt="" src={facebookIcon} />
            </button>
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(WillReadLater);
