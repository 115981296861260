export const loadingConstants = {
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
}

export const statisticsConstants = {
    STATISTICS_FETCHED: 'STATISTICS_FETCHED',
    STATISTICS_FAILED: 'STATISTICS_FAILED',
}

export const langConstants = {
    LANG_CHANGED: 'LANG_CHANGED',
}