import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json';
import he from './he.json';

let initialLang = localStorage.getItem('lang');

if (!initialLang) {
    initialLang = 'he';
}

if (initialLang === 'en') {
  document.body.classList.add('ltr');
} else {
  document.body.classList.remove('ltr');
}

const resources = {
  en: {
    translation: en
  },
  he: {
    translation: he
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: initialLang,

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;