import React, { Component, Fragment } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import history from './utils/history';
import Layout from './containers/Layout';
import LogRocket from 'logrocket';

import ReactGA from 'react-ga';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

LogRocket.init('2ad6px/pray-and-remember');

ReactGA.initialize('UA-133709791-6');

window.original_referrer = document.referrer;

history.listen((event) => {
    // update the current page on google analytics
    if (!event || !event.location) {
        return;
    }

    ReactGA.set({page: event.location.pathname});
    ReactGA.pageview(event.location.pathname);
});

const reload = () => window.location.reload();

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router history={history}>
          <Switch>
            <Route path='/privacy_policy.html' onEnter={reload} />
            <Route path="/privacy_policy" render={() => <Redirect
                  to={{
                      pathname: "/privacy_policy.html"
                  }}
              />}
              />
            <Route path='/' component={Layout} />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default connect(state => ({
}), {})(App);
