import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './style.scss';
import ynet from '../../assets/images/collaborators/ynet.png';
import kikar from '../../assets/images/collaborators/kikar_new.png';
import jerusalemPost from '../../assets/images/collaborators/the_jerusalem_post.png';
import steps from '../../assets/images/collaborators/steps.png';
import { isMobileDevice } from '../../utils';
import { mixpanelTrack, EVENT_NAMES } from '../../utils/mixpanel';
import { numberWithCommas } from '../../utils';
import srugim from "../../assets/images/collaborators/srugim.png";
import behadrei from "../../assets/images/collaborators/behadrei.png";

class About extends Component {
  componentWillMount() {
    mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_ABOUT);
  }
  componentWillUnmount() {
    mixpanelTrack(EVENT_NAMES.CLIENT_EXIT_ABOUT);
  }

  render() {
    const { statistics, t, lang } = this.props;
    let collaborators = [
      {
        image: steps,
        name: 'steps',
        link: 'https://www.steps.me/',
      },
      {
        image: kikar,
        name: 'kikar',
        link: 'https://www.kikar.co.il/',
      },
      {
        image: srugim,
        name: 'srugim',
        link: 'https://www.srugim.co.il/',
      },
      // {
      //   image: behadrei,
      //   name: 'behadrei',
      //   link: 'https://www.bhol.co.il/',
      // },
      // {
      //   image: jerusalemPost,
      //   name: 'jerusalem post',
      //   link: 'https://www.jpost.com/',
      // },
      // {
      //   image: ynet,
      //   name: 'ynet',
      //   link: 'https://www.ynet.co.il/',
      // },
    ];
    if (isMobileDevice()) {
      collaborators = [
        // {
        //   image: jerusalemPost,
        //   name: 'jerusalem post',
        //   link: 'https://www.jpost.com/',
        // },
        {
          image: kikar,
          name: 'kikar',
          link: 'https://www.kikar.co.il/',
        },
        // {
        //   image: ynet,
        //   name: 'ynet',
        //   link: 'https://www.ynet.co.il/',
        // },
        {
          image: steps,
          name: 'steps',
          link: 'https://www.steps.me/',
        },
        {
          image: srugim,
          name: 'srugim',
          link: 'https://www.srugim.co.il/',
        },
        // {
        //   image: behadrei,
        //   name: 'behadrei',
        //   link: 'https://www.bhol.co.il/',
        // },
      ];
    }
    return (
      <Fragment>
        <section className="about">
          <span className="mini-title">{t('aboutTheProject')}</span>
          <span className='title'>{t('prayAndRemember')}</span>
          <div className="about-text-inner">
            {lang === 'en' ? (
              <p className="ltr">
                The Coronavirus pandemic has undoubtedly strained the relations between differing  sectors of Israeli society. Harsh accusations were levelled and existing rifts were widened. Resentment and hostility threatens our existence no less than the pandemic or our surrounding enemies. Remembrance day for the fallen soldiers is an opportunity to bring together the country's fragmented society. We cannot 'impose' on our Haredi brethren to mourn in 'our' language. Each society has its cultural norms. We all hurt together, but we must allow each society to participate in the mourning in the way it sees fit.
                <br />
                <br />
                Last year, tens of thousands of Haredim marked the day by reciting Tehilim, each tehila being dedicated to the memory of a specific fallen soldier. The round-the-clock recitals across the country was joined by others from across Israel's tribes providing testimony to Israeli society's ability to unite.
                <br />
                <br />
                The Haredi community turns to the entirety of Israeli society to join them this year. Please read a chapter or two or even the whole book. The Haredi community grieves together with the rest of country by using one of our peoples most beautiful treasures through which generations have poured out their hearts for thousands of years.
              </p>
            ) : (
              <p>
                אירועי מגפת הקורונה השפיעו על מערכות היחסים בין קהילות שונות במדינת ישראל. מחד, ידענו לשתף פעולה ולהפיל מחיצות. מאידך, החברה הישראלית התמודדה עם גילויים חמורים של פילוג, פיצול ושיסוי.
                הוטחו האשמות הדדיות. נאמרו מילים חריפות. אש השנאה מסכנת את הקיום המשותף שלנו לא פחות ממגפת הקורונה שבפנים, ומאויבנו שמסביב.
                <br />
                יום הזיכרון לחיילי צה"ל הוא הזדמנות ליצירת חיבור בין כלל שבטי ישראל. אי אפשר 'להכריח' את אחינו החרדים להתאבל בשפה ״שלנו״.
                <br />
                לכל קהילה יש את הקודים התרבותיים שלה. כולנו כואבים יחד, אבל אנחנו חייבים לתת לכל קהילה להשתתף באבל בדרכה.
בשנה שעברה השתתפו עשרות אלפי חרדים באמירת פרקי תהילים לזכרם של חללי צה"ל. הם בחרו בחלל אחד והקדישו פרקי תהילים לעילוי נשמתו. התמונה המרגשת, בה במשך כשבוע ימים, אלפים רבים לאורך כל שעות היממה, בחרו להתייחד עם זכרם של הנופלים, מהוה ראיה ליכולת של החברה בישראל להתלכד.
                <br />
                הציבור החרדי פונה לכלל החברה הישראלית: אם אתם יכולים, הצטרפו אלינו. קראו גם אתם פרק, שניים, ספר.
הציבור החרדי יכול להיות שותף בעיצוב יום הזיכרון. להוסיף סממנים יהודיים המהווים ביטוי הולם לכאב ולאבל.
                <br />
                <br />
                למיזם זה מספר רב של שותפים וביניהם: כיכר השבת, סרוגים, בחדרי חרדים, חברת הסטארטאפ STEPS ועוד.
                <br />
                זוהי משימה משותפת לכל עם ישראל ולכל יהודי ישראל בעולם.
              </p>
            )}
          </div>
          {statistics && (
            <div className="about-site-details">
              {statistics.total_read_chapters_count ? (
                <div>
                  <span>{numberWithCommas(statistics.total_read_chapters_count)}</span>
                  <span>{t('chaptersWereRead')}</span>
                </div>
              ) : (
                <Fragment />
              )}
              {statistics.total_read_books_count ? (
                <div>
                  <span>{numberWithCommas(statistics.total_read_books_count)}</span>
                  <span>{t('booksWereRead')}</span>
                </div>
              ) : (
                <Fragment />
              )}
              {statistics.total_current_reading_chapters_count ? (
                <div>
                  <span>{numberWithCommas(statistics.total_current_reading_chapters_count)}</span>
                  <span>{t('areBeingReadNow')}</span>
                </div>
              ) : (
                <Fragment />
              )}
            </div>
          )}
        </section>
        <ul className="about-collaborators">
          {collaborators.map((collaborator, i) => {
            return (
              <li className={collaborator.name} key={i}>
                <a
                  href={collaborator.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img 
                      alt={collaborator.name} 
                      src={collaborator.image} 
                      style={{
                        filter: 'grayscale(100%)',
                        maxWidth: '123px',
                        opacity: collaborator.name === 'kikar' ? '0.5' : '1',
                        height: 'auto'}}/>
                </a>
              </li>
            );
          })}
        </ul>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    statistics: state.statistics,
    lang: state.lang,
  }),
  {},
)(withTranslation()(About));
