import { langConstants } from "../constants";

let initialState = localStorage.getItem('lang');

if (!initialState) {
    initialState = 'he';
}

export default (state = initialState, action) => {
    switch (action.type) {
        case langConstants.LANG_CHANGED:
            return action.payload;
        default:
            return state;
    }
}