import React, { Component, Fragment } from 'react';
import './style.scss';
// import { NavLink, Link } from 'react-router-dom';
import * as searchService from '../../services/search';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import history from '../../utils/history';
import { withTranslation } from 'react-i18next';
import { mixpanelTrack, EVENT_NAMES } from '../../utils/mixpanel';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchList: [],
      value: '',
    };
  }

  handleSearchChange = async (e) => {
    let value = e.target.value;
    if (!value) {
      this.setState((state) => ({
        searchList: [],
        value: '',
      }));
      if (this.props.onSearch) this.props.onSearch(false);
      return;
    }

    try {
      const res = await searchService.search(value);
      mixpanelTrack(EVENT_NAMES.CLIENT_SEARCH_FALLEN, { value });
      this.setState((state) => ({
        searchList: res.results,
        value,
      }));
      if (this.props.onSearch) this.props.onSearch(true);
    } catch (e) {}
  };

  handleFallenClick = (fallenId) => {
    this.setState((state) => ({ searchList: [], value: '' }));
    mixpanelTrack(EVENT_NAMES.CLIENT_SEARCH_FALLEN_SELECTED, { fallenId });

    history.push(`/${fallenId}`);
  };

  render() {
    const { searchList, value } = this.state;
    const { t } = this.props;

    return (
      <div className={`search-box ${searchList.length ? 'open' : ''}`}>
        <div className="search-form">
          <BasicInput
            debounce
            value={value}
            type="text"
            onChange={this.handleSearchChange}
            placeholder={t('searchFallen')}
          />
          <button type="button">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <div className="search-list">
          <ul>
            {searchList.length ? (
              searchList.map((fallen, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => this.handleFallenClick(fallen.fallen_uid)}
                  >
                    <img
                      alt={fallen.fallen_name}
                      src={fallen.fallen_image_url}
                    />
                    <span>
                      {fallen.fallen_rank} <span>{fallen.fallen_name}</span>
                    </span>
                  </li>
                );
              })
            ) : (
              <Fragment />
            )}
          </ul>
        </div>
      </div>
    );
  }
}

class BasicInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.hasOwnProperty('value')
        ? props.value
        : props.hasOwnProperty('defaultValue')
        ? props.defaultValue
        : '',
    };
  }

  timeout;
  handleChange = (e) => {
    e.persist();
    this.setState((state) => ({ ...state, value: e.target.value }));
    if (this.props.debounce) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.props.onChange) this.props.onChange(e);
      }, 500);
    } else {
      if (this.props.onChange) this.props.onChange(e);
    }
  };

  componentWillUnmount() {
    if (this.props.onDestroy) this.props.onDestroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      let value = nextProps.hasOwnProperty('value')
        ? nextProps.value
        : this.props.hasOwnProperty('defaultValue')
        ? this.props.defaultValue
        : '';
      if (value === undefined) value = '';
      this.setState(state => ( { ...state, value } ));
    }
  }

  render() {
    const {
      type = 'text',
      onBlur,
      placeholder = '',
      disabled = false,
      min,
      max,
      name,
    } = this.props;
    const { value } = this.state;
    return (
      <input
        min={min}
        max={max}
        ref={this.inputRef}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => this.handleChange(e)}
        onBlur={(e) => {
          if (onBlur) onBlur(e);
        }}
      />
    );
  }
}

export default withTranslation()(SearchBox);
