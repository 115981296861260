import { init } from 'mixpanel';
import { mixPanelId } from '../config';
import { getUserId } from './userSession';

export const EVENT_NAMES = {
  CLIENT_DONE_CHAPTER: 'CLIENT_DONE_CHAPTER',
  CLIENT_RELOAD_CHAPTER: 'CLIENT_RELOAD_CHAPTER',
  CLIENT_PRESSED_ABOUT: 'CLIENT_PRESSED_ABOUT',
  CLIENT_PRESSED_ANOTHER_FALLEN: 'CLIENT_PRESSED_ANOTHER_FALLEN',
  CLIENT_EXIT_ABOUT: 'CLIENT_EXIT_ABOUT',
  CLIENT_PRESSED_FALLEN: 'CLIENT_PRESSED_FALLEN',
  CLIENT_SEARCH_FALLEN: 'CLIENT_SEARCH_FALLEN',
  CLIENT_SEARCH_FALLEN_SELECTED: 'CLIENT_SEARCH_FALLEN_SELECTED',
  CLIENT_PRESSED_SHARE_BUTTON: 'CLIENT_PRESSED_SHARE_BUTTON',
};

const mixpanel = init(mixPanelId);

export const mixpanelTrack = async (event_name, event_data = {}) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  event_data.platform = 'CLIENT';
  event_data.user_uid = getUserId();
  event_data.original_referrer = window.original_referrer;
  await mixpanel.track(event_name, event_data);
};
