import { combineReducers } from 'redux';
import { loadingConstants } from '../constants';
import statistics from './statistics';
import lang from './lang';


const isLoading = (state = false, action) => {
  switch (action.type) {
    case loadingConstants.SHOW_LOADER:
      return true;
    case loadingConstants.HIDE_LOADER:
      return false;
    default:
      return state;
  }
}

const appReducer = combineReducers({
  statistics,
  lang,
  isLoading
})
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    delete state.user;
    delete state.isLoggedIn;
  }
  return appReducer(state, action);
}

export default rootReducer;