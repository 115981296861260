import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import './style.scss';
import facebookIcon from '../../assets/images/social-icons/facebook.png';
import gmailIcon from '../../assets/images/social-icons/gmail.png';
import socialIcon from '../../assets/images/social-icons/social.png';
import twitterIcon from '../../assets/images/social-icons/twitter.svg';
import { share } from '../../services/social';
import { withTranslation } from 'react-i18next';
import { mixpanelTrack, EVENT_NAMES } from '../../utils/mixpanel';
import { numberWithCommas } from '../../utils';

class Footer extends Component {
  handleShareClick = (social) => {
    const url = window.location.origin;
    const text = this.props.t('metaTitle');
    mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_SHARE_BUTTON, {
      social,
      url,
    });
    share(social, url, text);
  };

  render() {
    const { statistics, t, location } = this.props;
    const isAboutPage = location.pathname.includes('/about');
    return (
      <footer id="siteFooter">
        <ul className="footer-list">
          <li>
            <a href={'/'} className={!isAboutPage ? 'active' : ''}>
              {t('prayAndRemember')}
            </a>
          </li>
          <li>
            <Link to={'/about'} className={isAboutPage ? 'active' : ''}>
              {t('aboutTheProject')}
            </Link>
          </li>
        </ul>
        {isAboutPage ? (
          <div className="footer-contact">
            <span>{t('contact')}:</span>&nbsp;
            <span>prayandremember@steps.me</span>
          </div>
        ) : (
          <Fragment>
            {statistics && (
              <div className="footer-site-details">
                <div className="footer-site-details-text">
                  {t('total')}&nbsp;&nbsp;
                </div>
                {statistics.total_read_chapters_count ? (
                  <div className="footer-site-detail">
                    <span>{numberWithCommas(statistics.total_read_chapters_count)}</span>
                    &nbsp; {t('chaptersWereRead')}
                  </div>
                ) : (
                  <Fragment />
                )}
                {statistics.total_read_chapters_count &&
                  statistics.total_current_reading_chapters_count ? (
                  <div className="footer-site-details-divider">|</div>
                ) : (
                  <Fragment />
                )}
                {statistics.total_current_reading_chapters_count ? (
                  <div className="footer-site-detail">
                    <span>
                      {numberWithCommas(statistics.total_current_reading_chapters_count)}
                    </span>
                    &nbsp; {t('areBeingReadNow')}
                  </div>
                ) : (
                  <Fragment />
                )}
              </div>
            )}
          </Fragment>
        )}
        <div className="footer-social">
          <span>{t('share')}</span>
          <div>
            <button onClick={() => this.handleShareClick('email')}>
              <img alt="" src={gmailIcon} />
            </button>
            <button onClick={() => this.handleShareClick('whatsapp')}>
              <img alt="" src={socialIcon} />
            </button>
            <button onClick={() => this.handleShareClick('twitter')}>
              <img alt="" src={twitterIcon} />
            </button>
            <button onClick={() => this.handleShareClick('facebook')}>
              <img alt="" src={facebookIcon} />
            </button>
          </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      statistics: state.statistics,
    }),
    {},
  )(withTranslation()(Footer)),
);
