import React, { Component } from 'react';
import './style.scss';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import SearchBox from '../SearchBox';

class Sidebar extends Component {
    state = {
        isSearching: false
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('click', this.closeSidebarOnOutsideClick);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this._isMounted && this.props.isOpen !== nextProps.isOpen) {
            if (nextProps.isOpen) {
                document.addEventListener('click', this.closeSidebarOnOutsideClick);
            }
            else {
                document.removeEventListener('click', this.closeSidebarOnOutsideClick);
            }
        }
    }

    closeSidebarOnOutsideClick = (e) => {
        const sidebar = document.getElementById('sidebar');
        if (e.target !== sidebar && sidebar && !sidebar.contains(e.target)) {
            if (this.props.closeSidebar) this.props.closeSidebar();
        }
    }

    render() {
        const { isOpen, closeSidebar, t, location } = this.props;
        const { isSearching } = this.state;

        const isAboutPage = location.pathname.includes('/about');


        return (
            <div id='sidebar' className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className='sidebar-header'>
                </div>
                <SearchBox onSearch={(isSearching) => this.setState({ isSearching })} />
                {
                    !isSearching &&
                    <ul className='sidebar-menu-list'>
                        <li onClick={closeSidebar}>
                            <a href={'/'} className={!isAboutPage ? 'active' : ''}>
                                {t('prayAndRemember')}
                            </a>
                        </li>
                        <li onClick={closeSidebar}>
                            <Link to={'/about'} className={isAboutPage ? 'active' : ''}>
                                {t('aboutTheProject')}
                            </Link>
                        </li>
                    </ul>
                }
            </div>
        )
    }
}

export default withRouter(withTranslation()(Sidebar));