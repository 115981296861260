import axios from 'axios';

export class HttpClient {
    constructor(baseURL, responseType = "json") {
        this.client = axios.create({
            baseURL,
            responseType,
        });
        this._responseMiddleware();
        this._addOriginalReferrerToAllRequests();
    }

    setUserToken(token) {
        this.client.defaults.headers = {
            'Authorization': token
        }
    }

    async get(url, config = undefined) {
        return await this.client.get(url, config);
    }

    async post(url, data = undefined, config = undefined) {
        return await this.client.post(url, data, config);
    }

    _responseMiddleware() {
        this.client.interceptors.response.use((response) => {
            return response;
        });
    }

    _addOriginalReferrerToAllRequests() {
        this.client.interceptors.request.use((request) => {
            const originalRequestParams = request.params || {};

            request.params = {
                ...originalRequestParams,
                "original_referrer": window.original_referrer,
            };
            return request;
        });
    }
}

export default HttpClient;
