import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { createLogger } from 'redux-logger';
import Reducers from './reducers/';

import LogRocket from 'logrocket';

const logger = createLogger();
const middleware = [
    thunk,
    promise,
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && logger,
    LogRocket.reduxMiddleware(),
].filter(Boolean);
const store = createStore(
    Reducers,
    applyMiddleware(...middleware),
);

export default store;
