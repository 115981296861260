import React, { Component, Fragment } from 'react';
import './style.scss';
import { fallenConstants } from '../../constants/fallen';
import fallenCandle from '../../assets/images/fallen/candle.png';
import fallenReload from '../../assets/images/fallen/reload.png';
import chapterReadMark from '../../assets/images/fallen/read.png';
import logo from '../../assets/images/pray_and_remember.png';
import idfLogo from '../../assets/images/fallen/idf.png';
import * as fallenService from '../../services/fallen';
import history from '../../utils/history';
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import {
  showLoader,
  hideLoader,
  changeLang,
  updateStatistics,
} from '../../actions';
import Loading from '../Loading';
import { getUserSession } from '../../utils/userSession';
import Thanks from '../../components/Thanks';
import { mixpanelTrack, EVENT_NAMES } from '../../utils/mixpanel';
import WillReadLater from '../../components/WillReadLater';
import ynet from '../../assets/images/collaborators/ynet.png';
import kikar from '../../assets/images/collaborators/kikar_new.png';
import jerusalemPost from '../../assets/images/collaborators/the_jerusalem_post.png';
import steps from '../../assets/images/collaborators/steps.png';
import srugim from '../../assets/images/collaborators/srugim.png';
import { numberWithCommas } from '../../utils';
import behadrei from "../../assets/images/collaborators/behadrei.png";

function FallenStatistics(props) {
  const {
    fallen_read_books_count,
    fallen_read_chapters_count,
    fallen_current_reading_chapters_count,
    fallen_name,
    fallen_parents,
    fallen_gender
  } = props.stats;
  const { t } = useTranslation();
  if (
    (!fallen_read_books_count || fallen_read_books_count === 0) &&
    (!fallen_read_chapters_count || fallen_read_chapters_count === 0) &&
    (!fallen_current_reading_chapters_count ||
      fallen_current_reading_chapters_count === 0)
  ) {
    return <Fragment></Fragment>;
  }

  return (
    <div className="fallen-statistics">
      {(!fallen_read_books_count || fallen_read_books_count === 0) &&
        (!fallen_read_chapters_count || fallen_read_chapters_count === 0) ? (
        <Fragment></Fragment>
      ) : (
        <span className="fallen-statistics-right">
          <span style={{ marginLeft: '4px' }}>{fallen_gender ? t('wereReadInHisMemory') : t('wereReadInHisMemoryFemale')}:</span>
          {!fallen_read_books_count || fallen_read_books_count === 0 ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <span className="fallen-statistics-number">
                {numberWithCommas(fallen_read_books_count)}
                &nbsp;
              </span>
              {t('books')}
            </Fragment>
          )}
          {!fallen_read_chapters_count || fallen_read_chapters_count === 0 ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <span className="fallen-statistics-number">
                &nbsp;
                &nbsp;
                {numberWithCommas(fallen_read_chapters_count)}
                &nbsp;
              </span>
              {t('chapters')}
            </Fragment>
          )}
        </span>
      )}

      {fallen_current_reading_chapters_count &&
        fallen_current_reading_chapters_count > 0 ? (
        <Fragment>
          {' '}
          <span className="fallen-statistics-number">
            {numberWithCommas(fallen_current_reading_chapters_count)}
            &nbsp;
          </span>
          {t('chaptersAreBeingReadNow')} {fallen_name} {fallen_parents}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </div>
  );
}

function FallenImage(props) {
  const { src } = props;
  if (
    !src ||
    src.toLowerCase() === fallenConstants.defaultImage.toLowerCase()
  ) {
    return <div></div>;
  }

  return (
    <div className="fallen-image">
      <img alt="" src={src} />
    </div>
  );
}

function FallenDetails(props) {
  const { t } = useTranslation();
  const {
    fallen_rank,
    fallen_name,
    fallen_image_url,
    fallen_date_of_death,
    fallen_gender,
  } = props.details;
  return (
    <div className="fallen-details">
      <div className="fallen-name-box">
        <div>
          <h1>
            <span className="fallen-rank">{fallen_rank}</span>
            {fallen_name}
          </h1>
          <div className="fallen-death-date show-tablet">
            {fallen_date_of_death}
          </div>
        </div>
        <button
          onClick={props.openFallenWindow}
          className="button button-inverted about-fallen-button"
        >
          {fallen_gender ? t('readAbout') : t('readAboutFemale')}
        </button>
      </div>
      <div className="fallen-death-date hide-tablet">
        {fallen_date_of_death}
      </div>

      <FallenImage src={fallen_image_url} />
      <div className="fallen-candle">
        <img alt="" src={fallenCandle} />
      </div>
    </div>
  );
}

function FallenWindow(props) {
  const {
    fallen_parents,
    fallen_rank,
    fallen_name,
    fallen_unit,
    fallen_date_of_death,
    fallen_age,
    fallen_resting_place,
    fallen_story,
    fallen_image_url,
    fallen_gender,
  } = props.details;

  const { isOpen } = props;
  const { t } = useTranslation();

  return (
    <div
      className={`fallen-window ${isOpen ? '' : 'closed'}`}
      style={{ height: document.body.scrollHeight + 'px' }}
    >
      <div className='fallen-inner-window'>
        <div className="close-button show-tablet">
          <button className="button" onClick={props.closeWindow}>
            {t('closeAbout')}
          </button>
        </div>
        <div className="fallen-window-logo hide-tablet">
          <img alt="" src={logo} />
        </div>
        <div className="fallen-window-details">
          <img alt="" src={fallen_image_url} className="fallen-image" />
          <div className="fallen-window-name">
            <h1>
              <span className="fallen-rank">{fallen_rank}</span>
              {fallen_name}
            </h1>
            <div className="fallen-parents">{fallen_parents}</div>
          </div>
          <div className="close-button hide-tablet">
            <button className="button" onClick={props.closeWindow}>
              {t('closeAbout')}
            </button>
          </div>
        </div>
        <div className="fallen-more-details">
          <div className="idf-logo">
            <img alt="" src={idfLogo} />
          </div>
          <div>
            <ul>
              <li>{fallen_unit}</li>
              <li>{fallen_date_of_death}</li>
              <li>{fallen_age}</li>
              <li>{fallen_resting_place}</li>
            </ul>
          </div>
        </div>
        <div className="fallen-life-story">
          <h3>{fallen_gender ? t('lifeStory') : t('lifeStoryFemale')}</h3>
          <div className="fallen-life-story-inner">
            <p>{fallen_story}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

class Fallen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFallenWindowOpen: false,
      finishedReading: false,
      willReadLater: false,
    };
  }

  componentWillMount() {
    this.init();
  }

  openFallenWindow = () => {
    this.setState({ isFallenWindowOpen: true });
  };

  closeFallenWindow = () => {
    this.setState({ isFallenWindowOpen: false });
  };

  async init() {
    console.log('init');
    this.props.showLoader();
    const { id } = this.props.match.params;
    const fallen = await fallenService.getChapter(id);

    if (!fallen) {
      this.props.hideLoader();
      history.push('/error');
      return;
    }

    if (!id) {
      window.history.pushState(null, '', '/' + fallen.fallen_uid);
    }

    mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_FALLEN);

    this.setState({ fallen });
    this.props.hideLoader();
    this.props.updateStatistics({
      total_current_reading_chapters_count:
        fallen.total_current_reading_chapters_count,
      total_read_books_count: fallen.total_read_books_count,
      total_read_chapters_count: fallen.total_read_chapters_count,
    });

    let initialLang = localStorage.getItem('lang');
    if (!initialLang) {
      initialLang = fallen.language.toLowerCase();
      this.props.changeLang(initialLang);
    }
  }

  async changeChapter() {
    mixpanelTrack(EVENT_NAMES.CLIENT_RELOAD_CHAPTER);
    const fallen = await fallenService.getChapter(
      this.state.fallen.fallen_uid,
      true,
    );
    this.setState({ fallen });
    this.props.updateStatistics({
      total_current_reading_chapters_count:
        fallen.total_current_reading_chapters_count,
      total_read_books_count: fallen.total_read_books_count,
      total_read_chapters_count: fallen.total_read_chapters_count,
    });
  }

  finishedReading = async () => {
    mixpanelTrack(EVENT_NAMES.CLIENT_DONE_CHAPTER);

    await fallenService.updateSession(
      getUserSession(),
      fallenService.SESSSION_STATUS.COMPLETED,
    );
    this.setState({ finishedReading: true });
  };

  willReadLater = async () => {
    await fallenService.updateSession(
      getUserSession(),
      fallenService.SESSSION_STATUS.CANCELED,
    );
    this.setState({ willReadLater: true });
  };

  readAnotherChapter = async () => {
    const fallen = await fallenService.getChapter(
      this.state.fallen.fallen_uid,
      false,
    );
    this.setState({ fallen, finishedReading: false });
    this.props.updateStatistics({
      total_current_reading_chapters_count:
        fallen.total_current_reading_chapters_count,
      total_read_books_count: fallen.total_read_books_count,
      total_read_chapters_count: fallen.total_read_chapters_count,
    });
  };

  render() {
    const { fallen, finishedReading, willReadLater } = this.state;
    const { isLoading, t } = this.props;
    let collaborators = [
      {
        image: steps,
        name: 'steps',
        link: 'https://www.steps.me/',
      },
      {
        image: kikar,
        name: 'kikar',
        link: 'https://www.kikar.co.il/',
      },
      {
        image: srugim,
        name: 'srugim',
        link: 'https://www.srugim.co.il/',
      }
    ];
    if (isLoading) return <Loading />;

    if (!fallen) return <Fragment />;

    if (finishedReading)
      return (
        <Thanks
          readAnotherChapter={() => this.readAnotherChapter()}
          fallen={fallen}
        />
      );
    else if (willReadLater) {
      return <WillReadLater />;
    }

    const { fallen_name, chapter_string, chapter_text, fallen_parents } = fallen;

    return (
      <Fragment>
        <section className="fallen">
          <FallenWindow
            details={fallen}
            isOpen={this.state.isFallenWindowOpen}
            closeWindow={this.closeFallenWindow}
          />
          <FallenDetails
            details={fallen}
            openFallenWindow={this.openFallenWindow}
          />
          <div className="chapter-box">
            <h2>
              {t('chapterInMemoryOf')} {fallen_name} {fallen_parents}
            </h2>
            <div className="chapter-text">
              <span className="chapter-number">{chapter_string}</span>
              <div className="chapter-text-inner">
                <p>{chapter_text}</p>
              </div>
              <button
                className="reload-chapter-button"
                onClick={() => this.changeChapter()}
              >
                <img alt="" src={fallenReload} />
                {t('findAnotherChapter')}
              </button>
            </div>
            <button
              className="finish-reading-button"
              onClick={this.finishedReading}
            >
              <img alt="" src={chapterReadMark} />
              {t('iFinishedReading')}
            </button>
            <button
              className="can-not-read-now-button"
              onClick={this.willReadLater}
            >
              {t('cantReadNow')}
            </button>
            <FallenStatistics stats={fallen} />
          </div>
        </section>

        <ul className="about-collaborators" style={{ padding: '26px', margin: 'auto' }}>
          {collaborators.map((collaborator, i) => {
            return (
              <li className={collaborator.name} style={{filter: 'grayscale(100%)'}} key={i}>
                <a
                  href={collaborator.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img 
                      alt={collaborator.name} 
                      src={collaborator.image} 
                      style={{
                        maxWidth: '123px', 
                        opacity: collaborator.name === 'kikar' ? '0.5' : '1',
                        height: 'auto'}} />
                </a>
              </li>
            );
          })}
        </ul>
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    isLoading: state.isLoading,
  }),
  {
    showLoader,
    hideLoader,
    changeLang,
    updateStatistics,
  },
)(withTranslation()(Fallen));
