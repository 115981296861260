import { whatsappShareLink, facebookShareLink, twitterShareLink } from '../config';
import { querystringStringify } from '../utils';

const shareOptions = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600';


export const share = (social, url = '', text = '') => {
    if (navigator && navigator.share) {
        navigator.share({
            title: text,
            text: text || '',
            url
        }).then(() => { })
            .catch(error => { });
    } else {
        switch (social) {
            case 'whatsapp':
                shareOnWhatsapp(`${text} ${url}`);
                break;
            case 'twitter':
                shareOnTwitter(url, text);
                break;
            case 'facebook':
                shareOnFacebook(url, text);
                break;
            default:
                shareOnEmail(url, text)
                break;
        }
    }
}

export const shareOnWhatsapp = (text) => {
    const address = whatsappShareLink + querystringStringify({ text });
    window.open(address, '', shareOptions);
}

export const shareOnTwitter = (url, text) => {
    const address = twitterShareLink + querystringStringify({ url, text });
    window.open(address, '', shareOptions);
}

export const shareOnFacebook = (url, text) => {
    text = `\n#prayandremember\n#עםישראל\n${text}`;
    const address = facebookShareLink + querystringStringify({ u: url, t: text });
    window.open(address, '', shareOptions);
}

export const shareOnEmail = (url, text) => {
    const address = `mailto:?subject=${text}&Body=${text}\n${url}`;
    window.open(address, '');
}