import React, { Component } from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { share } from '../../services/social';
import facebookIcon from '../../assets/images/social-icons/facebook.png';
import gmailIcon from '../../assets/images/social-icons/gmail.png';
import socialIcon from '../../assets/images/social-icons/social.png';
import twitterIcon from '../../assets/images/social-icons/twitter.svg';
import { mixpanelTrack, EVENT_NAMES } from '../../utils/mixpanel';

class Thanks extends Component {
  handleShareClick = (social) => {
    const url = window.location.href;
    const text1 = this.props.t('fallenShare1');
    const text2 = `${this.props.fallen.fallen_name} ${this.props.fallen.fallen_parents}`;
    const text3 = this.props.t('fallenShare2');
    const text = `${text1} ${text2} ${text3}`;
    mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_SHARE_BUTTON, {
      social,
      url,
      fallen_id: this.props.fallen.fallen_uid,
    });

    share(social, url, text);
  };
  render() {
    const { t, fallen } = this.props;

    return (
      <section className="thanks">
        <div className="thanks-fallen-image">
          <img alt="" src={fallen.fallen_image_url} />
        </div>
        <div className="thanks-headlines">
          <div className="thanks-text">{t('thankYou')}</div>
          <div className="thanks-fallen-info">
            <span>{fallen.fallen_rank}</span> {fallen.fallen_name}{' '}
            {fallen.fallen_parents}
          </div>
        </div>
        <div className="thanks-buttons">
          <button onClick={this.props.readAnotherChapter}>
            <span>{t('readAnotherChapterInHisMemory')}</span>
          </button>
          <button
            onClick={() => {
              window.location.href = '/';
              mixpanelTrack(EVENT_NAMES.CLIENT_PRESSED_ANOTHER_FALLEN);
            }}
          >
            <span>{t('readAChapterInMemoryOfAnotherFallen')}</span>
          </button>
        </div>
        <div className="thanks-social">
          <span>{t('shareIn')}</span>
          <div>
            <button onClick={() => this.handleShareClick('email')}>
              <img alt="" src={gmailIcon} />
            </button>
            <button onClick={() => this.handleShareClick('whatsapp')}>
              <img alt="" src={socialIcon} />
            </button>
            <button onClick={() => this.handleShareClick('twitter')}>
              <img alt="" src={twitterIcon} />
            </button>
            <button onClick={() => this.handleShareClick('facebook')}>
              <img alt="" src={facebookIcon} />
            </button>
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(Thanks);
