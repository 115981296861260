const userSessionKey = 'sid';
export const setUserSession = (sessionId) => {
  localStorage.setItem(userSessionKey, sessionId);
};

export const getUserSession = () => {
  return localStorage.getItem(userSessionKey);
};

const userId = 'uid';
export const setUserId = (id) => {
  localStorage.setItem(userId, id);
};

export const getUserId = () => {
  return localStorage.getItem(userId);
};
