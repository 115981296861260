export const baseURL =
  process.env.REACT_APP_API_URL || 'https://api.prayandremember.org';

export const whatsappShareLink = 'https://api.whatsapp.com/send?'; // + text=https://www.seeya.co.il/

export const twitterShareLink = 'https://twitter.com/intent/tweet?'; // + url=https://www.seeya.co.il&via=seeya&text=seeya!

export const facebookShareLink = 'https://www.facebook.com/sharer/sharer.php?'; // + u=https://www.seeya.co.il&t=seeya!
export const mixPanelId =
  process.env.REACT_APP_MIXPANEL || 'd69d6199ed36733e0f4bc09cd121ece6';
