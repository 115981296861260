import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './style.scss';

class Error extends Component {

    componentWillMount() {
    }

    render() {
        const { t } = this.props;
        return (
            <section className='error-page'>
                <div className='error-num'>{t('error54')}</div>
                <div className='error-sorry'>{t('errorSorryMessage')}</div>
                <div className='error-sorry mobile'>{t('SorryError')}<br />{t('errorMessage')}</div>
                <a href='/'><span>{t('retry')}</span></a>
            </section >
        );
    }
}



export default withTranslation()(Error);