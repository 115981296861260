import { statisticsConstants } from "../constants";

const initialState = null;

export default (state = initialState, action) => {
    switch (action.type) {
        case statisticsConstants.STATISTICS_FETCHED:
            return action.payload;
        default:
            return state;
    }
}