import { statisticsConstants, langConstants, loadingConstants } from '../constants';
import * as statisticsService from '../services/statistics';

export const fetchStatistics = async () => {
    const data = await statisticsService.fetchStatistics();
    return (dispatch) => {
        if (data.statistics) {
            dispatch({
                type: statisticsConstants.STATISTICS_FETCHED,
                payload: data.statistics
            });
        } else {
            dispatch({ type: statisticsConstants.STATISTICS_FAILED });
        }
    }
}

export const updateStatistics = (statistics) => {
    return (dispatch) => {
        dispatch({
            type: statisticsConstants.STATISTICS_FETCHED,
            payload: statistics
        });
    }
}

export const showLoader = () => (dispatch) => {
    dispatch({
        type: loadingConstants.SHOW_LOADER
    });
}

export const hideLoader = () => (dispatch) => {
    dispatch({
        type: loadingConstants.HIDE_LOADER
    });
}

export const changeLang = async (lang) => {
    localStorage.setItem('lang', lang);
    if (lang === 'en') {
        document.body.classList.add('ltr');
    } else {
        document.body.classList.remove('ltr');
    }
    return (dispatch) => {
        dispatch({
            type: langConstants.LANG_CHANGED,
            payload: lang
        });
    }
}