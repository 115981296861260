import React from 'react';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import { isMobileDevice } from '../../utils';
import he from './he.json';
import en from './en.json';

const animationData = {
    he,
    en
}

class Loading extends React.Component {
    render() {
        const { lang } = this.props;
        const isMobile = isMobileDevice();
        const defaultOptions = {
            loop: true,
            autoplay: true,
            renderer: 'svg',
            animationData: animationData[lang],
        };

        return (
            <div className="Loading">
                <Lottie options={defaultOptions}
                    height={isMobile ? 320 : 400}
                    width={isMobile ? 320 : 400} />
            </div>
        );
    }
}

export default connect(state => ({
    lang: state.lang
}), {})(Loading);
