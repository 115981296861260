import { apiClient } from '../utils/apiClient';
import { getUserSession, setUserSession, setUserId, getUserId } from '../utils/userSession';
import { querystringStringify } from '../utils';

export const SESSSION_STATUS = {
  'COMPLETED': 'COMPLETED',
  'CANCELED': 'CANCELED',
};

export const updateSession = async (sessionId, status) => {
  try {
    const res = await apiClient.client({
      url: `/update_session/${sessionId}`,
      method: 'POST',
      data: {
        action: status,
      },
    });
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getChapter = async (id, isRejected = false) => {
  try {
    if (isRejected === true) {
      const userSession = getUserSession();
      await updateSession(userSession, SESSSION_STATUS.CANCELED);
    }

    let user_uid = undefined;
    const userId = getUserId()
    if (userId) {
      user_uid = userId;
    }

    const res = await apiClient.client({
      url: `/get_chapter?${querystringStringify({ id, user_uid })}`,
      method: 'GET',
    });
    if (!userId) {
      setUserId(res.data.user_uid);
    }
    setUserSession(res.data.session_uid);
    return res.data;
  } catch (error) {
    return null;
  }
};
