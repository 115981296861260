import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import Error from './Error';
import About from './About';
import Fallen from './Fallen';
import { fetchStatistics } from '../actions';

class Layout extends Component {
    componentWillMount() {
        this.props.fetchStatistics();
    }
    render() {
        let { isLoading, location } = this.props;
        const isFallenPage = !location.pathname.includes('/about');

        return (
            <div className={`layout ${isFallenPage ? 'fallen-page' : 'default-page'} ${isLoading ? 'loading' : ''}`}>
                {
                    !isLoading &&
                    <Header />
                }
                <div className={`layout-route`}>
                    {

                        <Switch>
                            <Route exact strict path='/' render={(props) => (
                                <Route component={Fallen} key={'FallenWithoutId'} {...props} />
                            )
                            } />
                            <Route path='/error' component={Error} />
                            <Route path='/about' component={About} />
                            <Route path='/:id' render={(props) => (
                                <Route component={Fallen} key={props.match.params.id} {...props} />
                            )
                            } />
                            {/* <Route component={NotFound} /> */}
                        </Switch>
                    }
                </div>
                {
                    !isLoading &&
                    <Footer />
                }
            </div>
        );
    }
}
export default withRouter(
    connect((state) => ({
        isLoading: state.isLoading
    }), { fetchStatistics })(Layout)
);