import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './style.scss';
import SearchBox from '../../components/SearchBox';
import { changeLang } from '../../actions';
import Sidebar from '../../components/Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { isMobileDevice } from '../../utils';
import prayLogo from '../../assets/images/pray_and_remember.png';

class Header extends Component {
  state = {
    isSidebarOpen: false,
  };

  openSidebar = () => this.setState({ isSidebarOpen: true });

  closeSidebar = () => this.setState({ isSidebarOpen: false });

  toggleSidebar = () =>
    this.setState({ isSidebarOpen: !this.state.isSidebarOpen });

  handleHamburgerClick = () => {
    this.toggleSidebar();
  };

  _changeLang = (lang) => {
    this.props.changeLang(lang);
    this.props.i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
  };

  render() {
    const { lang } = this.props;
    const { isSidebarOpen } = this.state;
    const isMobile = isMobileDevice();

    return (
      <header id="siteHeader">
        <nav>
          <div className="side-menu-button" onClick={this.toggleSidebar}>
            <button type="button">
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div className="header-logo">
            <a href="/">
              <img alt="Pray and rememeber" src={prayLogo} />
            </a>
          </div>
          <div className="header-lang">
            <button
              onClick={() => this._changeLang('en')}
              className={lang === 'en' ? 'active' : ''}
            >
              {isMobile ? 'EN' : 'English'}
            </button>
            <div>|</div>
            <button
              onClick={() => this._changeLang('he')}
              className={lang === 'he' ? 'active' : ''}
            >
              {isMobile ? 'HE' : 'Hebrew'}
            </button>
          </div>
          <SearchBox />
        </nav>
        <Sidebar closeSidebar={this.closeSidebar} isOpen={isSidebarOpen} />
      </header>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.lang,
  }),
  { changeLang },
)(withTranslation()(Header));
