import { apiClient } from '../utils/apiClient';


export const search = async (term) => {
    try {
        const res = await apiClient.client({
            url: `/search?term=${term}`,
            method: 'GET'
        });
        return res.data;
    } catch (error) {
        throw error;
    }
}